import React from "react";
import { Link as L } from "gatsby";
import { ContentSection, HeaderSection } from "../docs/sections";
import { DocPageMeta, Code, Header } from "../docs";
import { TextContainer, Paragraph, Ul } from "../../lib/src";

export const Meta: DocPageMeta = {
	category: "Developing",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Component architecture guidelines"
			subTitle="These are the guidelines we strive to follow when creating components"
		/>
		<ContentSection>
			<TextContainer article>
				<Header.H2>Self encapsulating</Header.H2>
				<Paragraph>
					By default, components should not affect outward, only inward. That means a component only
					has an effect on itself and its children, not its siblings or parents. An example for this
					are margins on the component itself, which should be avoided because margins affect
					outward. Instead, components should use padding, for spacing itself and its children.
				</Paragraph>
				<br />
				Current known violators:
				<Ul
					items={[
						<L key="1" to="/lib/components/Structure/Card">
							Card
						</L>,
						<L key="2" to="/lib/components/Visuals/Badge">
							Badge
						</L>,
						<L key="3" to="/lib/components/Actions and controls/Button">
							Button
						</L>,
					]}
				/>
				Button might be the exception to the rule, the other two are planned to be fixed.
				<Header.H2>Controlled</Header.H2>
				<Paragraph>
					Components should in general be controlled, i.e. have data provided via props and provide
					event for when said data should be changed. For example in <Code>InputField</Code> the
					current <Code>value</Code>
					is provided via props, and the <Code>onChange</Code> property should be used by the parent
					to change the <Code>value</Code> property accordingly.
				</Paragraph>
				<Paragraph>
					A larger example could be <Code>Table</Code>, where all the data is provided via props and
					events such as <Code>onSort</Code> is used to control changes to the data.
				</Paragraph>
				<Header.H2>Composable</Header.H2>
				<Paragraph>
					When possible try to expose meaningful sub parts of a component. This allows edge cases to
					reuse the majority a component when diverging from the happy path.
				</Paragraph>
				<Paragraph>
					An example of this is <Code>Card.Header</Code> which is used to render the upper part of a
					card. By being exposed as a sub component instead of being a property on <Code>Card</Code>{" "}
					it allows the user easier use a modified version. Perhaps there is a case where we want a
					drag and dropable card and the 'drag-zone' should be the header. With this design it is
					hopefully possible to make a new sub component <Code>DragableCardHeader</Code> that
					composes <Code>Card.Header</Code> with the added functionality while keeping the look and
					feel of the original, such that when it is updated in the future this will update with it.
					In general it should be easy to build the common/default case, but possible to deviate
					with as much reuse as possible.
				</Paragraph>
			</TextContainer>
		</ContentSection>
	</>
);
